.footer {
  background-color: #974C62;
  padding: 20px 0;
  color: #F2F2F0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 64%;
  margin: 0 auto;
  padding: 20px;
  flex-direction: row;
}

.footer-socials,
.footer-location,
.footer-schedule{
  flex: 1;
  margin: 10px;
  width: 300px;
  max-width: 400px;
  /* height: 200px; */
  /* border-color: #F2F2F0; */
  border:solid;
  border-width: 2px;
  text-align: center;
}

.footer-socials {
  flex: 2;
}

.footer-location {
  flex: 3;
}

.footer-schedule {
  flex: 2;
}

.footer-subscribe {
  flex: 3;
}

.footer-location h4,
.footer-schedule h4,
.footer-socials h4 {
  font-size: 25px;
  margin-bottom: 10px;
}


.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive design adjustments */
@media (max-width: 767px) {
  .footer {
    padding: 5px 0;
  }

  .footer-container {
    flex-direction: row;
    align-items: center;
    /* padding: 0px !important; */
    min-width: 80%;
  }

  .footer-socials,
  .footer-location,
  .footer-schedule {
    /* margin: 10px; */
    /* padding: 10px; */
    flex: none;
    text-align: center;
    min-height: 150px;
    max-width: 300px;
  }

  .social-links {
    margin-top: 5px !important;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px !important;
  }

  .footer-socials,
  .footer-location,
  .footer-schedule {
    flex: 1 1 45%;
    text-align: center;
    min-height: 150px;
    max-width: 300px;
  }

  .social-links {
    margin-top: 5px !important;
  }
}

/* Social links container */
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px; /* Adds space between the icons */
  margin-top: 50px;
}

/* Social link icons */
.social-link-btn {
  width: 40px; /* Adjust the size of icons */
  height: 40px;
  transition: filter 0.3s ease; /* Smooth hover transition */
}

